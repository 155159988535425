<template>
    <div class="wrapper_main">
        <main class="content">
            <div class="content__header">
                <div class="content__header-left">
                    <router-link to="/categories" class="btn btn-w-shadow btn-sm btn-back">
                        <inline-svg :src="require('@/assets/img/Arrow-prev.svg')" />
                    </router-link>

                    <div class="heading-wrapper flex items-center" v-if="editName.show" >
                        <button class="btn btn-transp btn-rename" @click="editNameSubmit" :class="{processing}">
                            <inline-svg :src="require(`@/assets/img/checkmark-circle.svg`)" />
                        </button>
                        <input type="text" class="input head-controls__input" v-model="editName.name" />
                    </div>
                    <div class="heading-wrapper flex items-center" v-else>
                        <button class="btn btn-transp btn-rename" @click="editNameOpen">
                            <img src="@/assets/img/Pen-Edit.svg" />
                        </button>
                        <h1 class="heading-page-h1">{{ currentCategory.name }}</h1>
                    </div>

                </div>
                <div class="content__header-right">
                    <button type="button" class="btn btn-primary btn-standart"  @click="addProductsOpen">
                        <SvgIcons icon="ico-remove.svg" />
                        <span>{{ $translate('add-a-product') }}</span>
                    </button>
                </div>
            </div>

			<TableAdvanced v-model="sortCol" :rows="filteredRows" :columns="columns" @resetFilters="resetFilters" :noDataMessage="noDataMessage"  :isCheckedAll="isCheckedAll" :checkedInputs="checkedInputs" @toggleAllCheckedInputs="toggleAllCheckedInputs">
                <template v-slot:filters>
                    <div class="filter-options__search" style="max-width: 412px">
                        <input type="search" class="input bg-left search-icon" :placeholder="$translate('search-by-name,-id,-rfid,-serial#')" 
                                v-model="rowsFilters.search"/>
                    </div>
                    <div class="filter-options__items flex-x-wrap flex-auto">
                        <!-- <DropdownAdvanced :options="deviceOptions" v-model="rowsFilters.device" /> -->
                        <!-- <DropdownAdvanced :options="productsStatusOptions" v-model="rowsFilters.status" /> -->
                        <DatePicker v-model="rowsFilters.manufacturing_date" />
                    </div>
                </template>
                <template v-slot:row="slotData">
                    <td>
                        <label class="module__check long-text-wrapper">
                            <input type="checkbox" :checked="checkedInputs[slotData.r.id]" @change="setCheckedInputs($event,slotData.r.id)"/>
                            <span class="check"></span>
                            <span class="text long-text-stroke">{{ slotData.r.id }}</span>
                        </label>
                    </td>
               
                    <td>
                        {{ slotData.r.title }}
                    </td>
                    <td>
                        {{ slotData.r.rfid_number }}
                    </td>
                    <td>
                        {{ slotData.r.serial_number }}
                    </td>
                    <td>Custom Device Name 1</td>
                    <td>
                        <span class="table-statys enable"><span class="dot"></span> {{ slotData.r.status }}</span>
                    </td>
                    <td>
                        {{ slotData.r.company_name }}
                    </td>
                    <td style="padding-right: 50px">
                        {{ slotData.r.manufacturing_date }}
                    </td>
                    <td class="position-stiky">
                        <div class="table-options-drop dropdown-wrapper">
                            <button type="button" class="btn btn-sm btn-transp dropdown-btn" @click="openOptions">
                                <inline-svg :src="require('@/assets/img/dots-menu.svg')" />
                            </button>
                            <div class="categories__dropdown dropdown-content">
                                <ul>
                                    <li>
                                        <a href="#">+ Add to Dispatch</a>
                                    </li>
                                    <li>
                                        <router-link :to="`/products/${slotData.r.id}`">{{ $translate('view-product') }}</router-link>
                                    </li>
                                    <li class="list-line"></li>
                                    <li>
                                        <button type="button" class="btn red-color" data-target="delete-item-2" @click="confirmDeleteOpen(getDeleteRelaionId(currentCategory.products.items, 'categoryID',currentCategory.id,'productID',slotData.r.id))">{{ $translate('delete-product') }}</button>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </td>
                </template>
            </TableAdvanced>
        </main>
	   <Pagination v-model="filteredRows" :items="rawSource" />
       <ConfirmAction 
            v-if="deletePopupInfo.show" 
            :title="$translate('delete-product?')" 
            :message="$translate('do-you-confirm-deleting-this-product-from-category?')" 
            @close="confirmDeleteClose" 
            @confirm="confirmDeleteAction" 
            :processing="dProcessing"
        />
        <!-- 
            before translate
            title="Delete Product?" 
            message="Do you confirm deleting this product from category?" 
         -->
        <AddProducts
            v-if="addProductsPopupInfo.show"
            :products="addProductsPopupInfo.products"
            @close="addProductsClose"
            @submit="addProductsSubmit"
            :processing="aProcessing"
            :title="$translate('add-products-to-category')"
        />
        <!-- before translate title="Add products to category" -->
    </div>
</template>

<script>
import { API } from 'aws-amplify';
import {
	updateCategory,
    createProductsCategory,
    
} from '@/graphql/mutations';


import Base from "@/components/base";
import Buttons from "@/components/buttons";
import Popups from "@/components/popups"
import { mixTables,mixDeleteItem,mixArrayFields,mixProcessing } from "@/mixins";

export default {
    name: "CategoriesView",
    mixins: [mixTables,mixDeleteItem,mixArrayFields,mixProcessing],
	props:['id'],
	components: {
        ...Base,
		...Buttons,
        ...Popups
    },
    data() {
        return {
			source: "categories",
			columns: [
				{ name: "Product ID", filter: "id" },
				{ name: "Product Name", filter: "title" },
				{ name: "RFID №", filter: "rfid_number" },
				{ name: "Serial", filter: "serial_number" },
				{ name: "Device", filter: "deviceName" },
				{ name: "Status", filter: "status" },
				{ name: "Manufacturer", filter: "test_is_cacl_field" },
				{ name: "Manufacturing Date", filter: "manufacturing_date" },
			],
            calculatedFields:[{
				filter: 'deviceName',
				path: ['devices','items'],
				calcFunction: (arr)=>arr[arr.length-1]?.name 
			}], 
			searchField:"title",
			noDataMessage:"Products in category not found",
            //delete config
            deleteQueryType:"deleteProductsCategory",
            // deleteEntryName:"categoryProductsId"
            editName:{
                show:false,
                name:null
            }
		};
    },
    computed: {
        categories() {
            return this.$store.state.categories || [];
        },
        products() {
            return this.$store.state.products || [];
        },
		currentCategory() {
			return this.categories.find((c) => c.id === this.id) || {products:{items:[]}};
		},
		sourceData(){
            let fullProducts=[]
            this.currentCategory.products.items.forEach(p=>{
                let fullProduct=this.products.find(fp=>{
                    return fp.id==p.productID
                })
                if(fullProduct){
                    fullProducts.push(fullProduct)
                }
            })
			return fullProducts;
		},

    },
    // async created() {
    //     if (this.categories.length&&this.categories.length) return;
    //     await this.refreshData();
    // },
    methods: {
        async refreshData() {
            await this.$store.dispatch("GET_CATEGORIES");
            await this.$store.dispatch("GET_PRODUCTS");
        },
        editNameOpen(){
            this.editName.show=true
            this.editName.name=this.currentCategory.name
        }, 
        editNameClose(){
            this.editName.show=false
            this.editName.name=null
        }, 
        async editNameSubmit(){
            this.processing = true;

            const category = await API.graphql({
                query: updateCategory,
                variables: {
                    input: {
                        id: this.id,
                        name:this.editName.name,
                    },
                },
            }).then((res) => res.data.updateCategory);
            this.processing=false
            this.$store.commit('UPDATE_ITEM', { field: 'categories', item: category })
            this.editNameClose()
            this.processing = false;
          
        },
		openOptions(ev) {
            ev.target.classList.toggle("active");
			ev.target.nextElementSibling.classList.toggle('active');
        },
        // async deleteProductCategorySubmit(productId) {
        //     console.log(this.deletePopupInfo.id);
        //     // API.graphql({
        //     //     query: updateProduct,
        //     //     variables: {
        //     //         input: {
        //     //             productID: null,
        //     //             categoryID: this.id,
        //     //         },
        //     //     },
        //     // }).then((res) => res.data.updateProduct)
        //     // this.addProductsClose();
        //     // this.refreshData();
        // },
        addProductsOpen() {
            let currentCategoryProducts=this.currentCategory.products.items.length ? this.transformFieldToId(this.currentCategory.products.items) : [];
            this.addProductsPopupInfo.products=this.filterFreeObjects(this.products,"category",currentCategoryProducts)
            this.addProductsPopupInfo.category=this.currentCategory
            this.addProductsPopupInfo.show=true
        },
        async addProductsSubmit(ev) {
            this.aProcessing=true
            const requests = ev.map((productID) =>
                API.graphql({
                    query: createProductsCategory,
                    variables: {
                        input: {
                            productID: productID,
                            categoryID:  this.id  
                        },
                    },
                }).then((res) => res.data.updateProduct)
            );
            await Promise.all(requests);
            this.refreshData();
            this.aProcessing=false
            this.addProductsClose();
        },


    },


};
</script>
